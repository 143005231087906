/* product item */
.product-item {
	.-slider {
		margin-top: 20px;
		height: 80vh;
		width: 100%;
		object-fit: contain;

		@media screen and (max-width: 600px) {
			height: auto;
			margin-top: 5px;
		}
	}

	.-text {
		font-family: 'Avenir', sans-serif;
		font-weight: 400;
		font-size: 22px;
		@media screen and (max-width: 600px) {font-size: 18px;}
		&.xl {
			font-size: 60px;
			margin-bottom: 15px;
			margin-top: 15px;
			@media screen and (max-width: 600px) {font-size: 48px;}
		}
		&.bd { font-weight: 700; }
	}


	.-specs {
		margin-top: 30px;
		margin-bottom: 50px;
		.-item {margin-bottom: 4px;}
	}

	.-links-wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-bottom: 30px;
		gap: 40px;
		@media screen and (max-width: 600px) { gap:20px }
		.-link {
			font-family: 'Avenir', sans-serif;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 200px;
			height: 130px;
			background: #FFFFFF;
			box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.15);
			border-radius: 5px;
			color: #363636;
			font-size: 24px;
			font-family: 'Cambay';
			transition: 300ms;

			&:hover {
				box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
			}

			@media screen and (max-width: 600px) {
				width: 140px;
				height: 90px;
				font-size: 20px;
			}
		}
	}
}



