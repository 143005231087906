.gallery-title {
	font-family: 'Avenir', sans-serif;
	font-size: 36px;
	text-align: center;
	margin-top: 10px;
}

.gallery-wrapper {
	position: relative;
	text-align: center;
	color: white;
	margin: 20px 10px;
	transition: 250ms;
	box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
	cursor: pointer;
	object-fit: contain;
	&:hover {
		box-shadow: 5px 15px 15px rgba(0, 0, 0, 0.20);
	}
	&:active {
		opacity: 0;
		transform: scale(1.1);
	}
	.-img {
		width: 100%;
		min-height: 200px;
		height: auto;
		object-fit: cover;
		@media only screen and (max-width: 1000px) {
			min-height: 100px;
		}
	}
}