/* collections */
.collections {
	display: grid;
	grid-template-columns: auto auto auto;
	padding: 0;
	margin: 0;
	gap: 0;
	width: 100%;
	@media screen and (max-width: 1024px) {
		grid-template-columns: auto;
	}
	.-item {
		text-align: center;
		margin: 0;
		padding: 0;
		transition: 200ms;
		position: relative;
		text-align: center;
		color: rgb(0, 0, 0);
		transition: 250ms;
		cursor: pointer;
		overflow: hidden;
		object-fit: contain;
		background-color: rgba(0, 0, 0, 9);
		&:hover > .-img {
			opacity: 0.5;
			transition: opacity 250ms linear, transform 250ms linear;
			cursor: pointer;
			filter: grayscale(.5)
		}
		.-img {
			width: 100%;
			height: 82vh;
			opacity: 0.85;
			min-height: 500px;
			filter: grayscale(.3);
			object-fit: cover;

			@media screen and (max-width: 1024px) {
				width: 100%;
				min-height: 250px;
				height: auto;
			}
		}
		.-text {
			font-family: 'Avenir', sans-serif;
			font-size: 48px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 75%;
			color: #ffffff;
			@media screen and (max-width: 1024px) {
				font-size: 32px;
			}
		}
	}	
}