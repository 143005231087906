.product-type-title {
	font-family: 'Avenir', sans-serif;
	font-size: 28px;
	font-weight: 700;
	text-align: left;
	margin-top: 10px;
	margin-left: 5px;
}

.sticky {
	position: -webkit-sticky;
	position: sticky;
	top: 80px;
	z-index: 5;
}

.filter {
	background: #363636;
	padding: 10px 36px !important;
	display: flex;
	flex-direction: row;
	gap: 60px;
	justify-content: left;
	flex-wrap: wrap;
	@media screen and (max-width: 768px) {
		gap: 30px;
	}
	.-link {
		color: #fff;
		display: block;
		padding: 0px 0px !important;
		margin: 0;
		text-align: left;
		text-decoration: none;
		transition: 250ms;
		font-size: 14px;
		border-radius: 1px;
		cursor: pointer;
		@media screen and (max-width: 768px) {
			padding: 0px 0;
			font-size: 12px;
		}
		&.active {
			text-shadow: 0px 0px 15px rgba(255, 255, 255, 0.47);
		}
	}
}