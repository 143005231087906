.contacts-title {
  margin-top: 25px;
  font-size: 48px;  
  font-weight: 500;
  font-family: 'Avenir', sans-serif;
  @media only screen and (max-width: 1000px) {font-size: 36px;}
}
.contacts-wrapper {
  margin-top: 10px;
  margin-bottom: 30px;
  .-text {
    font-size: 20px;
    font-weight: 500;
    font-family: 'Avenir', sans-serif;
    @media only screen and (max-width: 1000px) {font-size: 16px;}
    &.orange {
      color: rgb(225, 153, 21);
    }
    &.xl {
      font-size: 26px;
      font-weight: 700;
      @media only screen and (max-width: 1000px) {
        font-size: 24px;
      }
      &.work-time {margin-top: 30px;}
      &.special {
        height: 78px;
        @media only screen and (max-width: 1000px) {
          height: auto;
        }
      }
    }
  }
}