.product-landing {
	transition: 500ms;
	padding-bottom: 2px;
	margin-bottom: 25px;
	/* box-shadow: 0px 15px 15px  rgba(59, 59, 59, 0.05); */
	border-radius: 2px;
	&:active {
		opacity: 0;
		transform: scale(1.1);
	}
	&:hover {
		box-shadow: 0px 10px 10px rgba(59, 59, 59, 0.10);
	}
	.-text {
		margin-top: 5px;
		margin-bottom: 10px;
		font-family: 'Avenir', sans-serif;
		text-align: center;
		font-size: 18;
		color: rgb(25, 25, 25);
		text-decoration: none;
	}
	.-img {
		max-width: 100%;
	}
}

a:link {
	text-decoration: none;
}

a:visited {
	text-decoration: none;
}