.catalogs-title {
	font-family: 'Avenir', sans-serif;
	font-size: 36px;
	text-align: center;
	margin-top: 10px;
}

.catalog {
	margin-bottom: 50px;
	display: flex;
	justify-content: center;
	flex-direction: column;

	.-link {
		display: flex;
		justify-content: center;

		.-img {
			filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
			min-height: 355px;
			background-color: #f0f0f0;
			height: 355px;
			display: flex;
			width: auto;
			margin-top: 20px;
			margin-bottom: 10px;
			transition: 250ms;

			&:hover {
				filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2));
			}

			@media screen and (max-width: 1500px) {
				height: 305px;
				min-height: 305px;
			}

			@media screen and (max-width: 1200px) {
				height: 260px;
				min-height: 260px;
			}
		}
	}

	.-text {
		display: block;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		font-family: 'Avenir', sans-serif;
		font-size: 16px;
	}
}