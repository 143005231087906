.navbar {
	margin: 0;
	height: 80px;
	width: 100%;
	background: rgb(54, 54, 54);
	color: white;
	justify-content: space-between;
	align-items: center;
	padding: 0 36px !important;
	font-size: 14px;
	@media only screen and (max-width: 1000px) { z-index: 289; }
	.-logo {
		padding: 0;
		margin: 0;
		text-decoration: none;
		color: white;
	}

	.-icon {
		display: none;
		font-size: 2rem;
		cursor: pointer;

		@media only screen and (max-width: 1000px) {
			display: flex;
		}
	}
	.-links {
		padding: 0;
		margin: 0;
		justify-items: center;
		display: grid;
		grid-template-columns: repeat(8, auto);
		grid-gap: 50px;
		list-style: none;
		@media only screen and (max-width: 1000px) {
			grid-gap: 35px;
			display: flex;
			flex-direction: column;
			position: absolute;
			text-align: center;
			width: 100%;
			top: 80px;
			left: -100%;
			transition: 0.5s all;
			padding-bottom: 10px;
		}
		&.active {
			@media only screen and (max-width: 1000px) {
				background: rgb(54, 54, 54);
				left: 0;
			}
		}
		.-item {
			@media only screen and (max-width: 1000px) {
				padding: 10px 0;
			}
			.current {
				color: #fff;
				text-decoration: none;
				transition: 0.3s all;
				text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.35);
				&:hover {color: #fff;}
			}
			.inactive {
				color: rgb(230, 230, 230);
				text-decoration: none !important;
				transition: 0.3s all;
				&:hover {color: #fff;}
			}
		}
	}
}