#container {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
}

#main-content {
	margin-top: 80px;
	flex: 1;
}

#header-content {
	display: flex;
}

select {
	background: rgba(255, 255, 255, 0.85);
	border: none;
	border-radius: 2px;
	padding: 1px 3px;
	text-align: center;
}

select option {
	background: rgba(0, 0, 0, 0.8);
	color: #fff;
	text-shadow: 0 1px 0 rgba(0, 0, 0, 0.6);
}

a:link {
	text-decoration: none;
}

a:visited {
	text-decoration: none;
}