.furniture-components {
	margin: 20px 0;
	.-text {
		font-family: 'Avenir', sans-serif;
		font-size: 16px;
		margin-left: 5px;
		&.xl {
			font-weight: 700;
			font-size: 24px;
			margin-bottom: 5px;
			margin-top: 15px;
		}
	}
	.-img {
		width: 100%;
		height: auto;
		min-height: 200px;
		background-color: #f0f0f0;
		box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.4);
		transition: 350ms;
		cursor: pointer;
		&:hover {
			box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.50);
		}
	}
}